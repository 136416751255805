import Vue from "vue";
import VueRouter from "vue-router";
import pageRouter from "./pageRouter";
import {data} from '../apis';

Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    base: '/wuhu/',
    routes: [
        ...pageRouter
    ]
});
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    let orgId = to.query.orgId
    if(orgId){
        data.orgId = orgId;
        localStorage.setItem('orgId',orgId)
    }
    // else{
    //     data.orgId = localStorage.getItem('orgId') || ''
    // }
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
    // if(to.meta.requireAuth){
    //     if(localStorage.getItem('info')){
    //         next()
    //     }else{
    //         next({
    //             path: '/login',
    //             query: {redirect: to.fullPath}
    //         })
    //     }
    // }else{
    //     next()
    // }
})
router.afterEach((to, from, next) => {

})
export default router;
