import axios from 'axios';
import { Toast } from 'vant';
import { data} from './apis';
axios.defaults.withCredentials=true
// axios.defaults.baseURL = 'https://pw.bzwljt.cn/client'
// axios.defaults.baseURL = 'http://wuhu.dev.52qmp.com/client-feature/';
// const baseURL = 'http://wuhu.dev.52qmp.com/client-feature/'
if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = data.baseURL;
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // Toast.loading({
    //     duration: 300,
    //     message: '加载中...',
    //     forbidClick: true,
    //     loadingType: 'spinner',
    //   });
    // 在发送请求之前做些什么
    config.headers = {
        'content-type': 'application/x-www-form-urlencoded',
    }
    return config
}, function (error) {
    console.log(error)
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    //对响应数据做点什么
    if(response.data.code==300){
        Toast({
            message: '请求失败'+response.data.message,
            icon: 'fail',
        });
    }
    if(response.data.code==401){
        Toast({
            message: '未登录，请使用“皖事通”APP使用',
            icon: 'fail',
        });
    }
    // if(response.data.code==400){
    //     localStorage.removeItem('info');
    //     router.replace({
    //         path: '/',
    //         query: {redirect: router.currentRoute.fullPath}
    //     })
    // }
    return response.data
}, function (error) {
    // 对响应错误做点什么
    // Message.error({
	// 	message: '加载失败'
	// })
    return Promise.reject(error)
})

export let baseURL
export default axios;