<template>
    <div>
        <van-popup
            :close-on-click-overlay="false"
            v-model="show" style="background-color:transparent"
        >
            <div class="loadingWrap">
                <van-loading v-if="show" text-color="#0094ff">加载中...</van-loading>
            </div>
        </van-popup>
    </div>
    <!-- <van-popup 
        :close-on-click-overlay="false"
        v-model="show" style="background-color:transparent"
    >
        <div class="loadingWrap">
            <van-loading v-if="show" text-color="#0094ff">加载中...</van-loading>
        </div>
    </van-popup> -->
</template>

<script>
export default {
    name:'loading',
    data () {
        return {
            show:false,
        }
    },
   
}
</script>

<style  scoped>
.loadingWrap .van-popup{
    background-color:rgba(0,0,0,.4) 
    
}
</style>