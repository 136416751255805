import Vue from "vue"
import Vuex from "vuex"
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state:{
        indexList:[],
        orgInfo:{},
        orders:[],//
        successLogin:false,
        token:'',// 皖事通token
        user:{},
        payUrl:'',
    },
    mutations:{
        saveIndexList(state,indexList){
            state.indexList = indexList;
        },
        saveOrgInfo(state,orgInfo){
            state.orgInfo = orgInfo;
        },
        saveOrders(state,orders){
            state.orders = orders;
        },
        saveToken(state,token){
            state.token = token;
        },
        saveUser(state,user){
            state.user = user;
        },
        saveSuccessLogin(state,successLogin){
            state.successLogin = successLogin;
        },
        savePayUrl(state,payUrl){
            state.payUrl = payUrl;
        },
    }
})