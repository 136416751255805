const pageRouter = [
    
    {
        path: "/",
        name: "index",
        meta: {
            title: "社会保障卡居民服务“一卡通",
            requireAuth:true
        },
        component: () => import("@/views/index/index")
    },
    {
        path: "/index",
        name: "index",
        meta: {
            title: "门票专区",
            requireAuth:true
        },
        component: () => import("@/views/index/index"),
        // children:[
        //     {
        //         path: "/index/search",
        //         name: "search",
        //         meta: {
        //             title: "搜索",
        //             requireAuth:true
        //         },
        //         component: () => import("@/views/index/search")
        //     },
        // ]
    },
    {
        path: "/search",
        name: "search",
        meta: {
            title: "搜索",
            requireAuth:true
        },
        component: () => import("@/views/index/search")
    },
    {
        path: "/info",
        name: "info",
        meta: {
            title: "详情",
            requireAuth:true
        },
        component: () => import("@/views/index/info")
    },
    {
        path: "/order",
        name: "order",
        meta: {
            title: "我的订单",
            requireAuth:true
        },
        component: () => import("@/views/order/index")
    },
    {
        path: "/addOrder",
        name: "order",
        meta: {
            title: "订单填写",
            requireAuth:true
        },
        component: () => import("@/views/order/addOrder")
    },
    {
        path: "/order/orderInfo",
        name: "orderInfo",
        meta: {
            title: "订单详情",
            requireAuth:true
        },
        component: () => import("@/views/order/orderInfo")
    },
    {
        path: "/info/productInfo",
        name: "productInfo",
        meta: {
            title: "门票详情",
            requireAuth:true
        },
        component: () => import("@/views/index/mpxuzhi")
    },
    {
        path: "/xuzhi",
        name: "infxuzhio",
        meta: {
            title: "游客须知",
            requireAuth:true
        },
        component: () => import("@/views/index/xuzhi")
    },
    {
        path: "/personal",
        name: "personal",
        meta: {
            title: "个人中心",
            requireAuth:true
        },
        component: () => import("@/views/personal/index")
    },
    {
        path: "/personal/personalInfo",
        name: "personalInfo",
        meta: {
            title: "账号信息",
            requireAuth:true
        },
        component: () => import("@/views/personal/info")
    },
    {
        path: "/personal/chuyouren",
        name: "chuyouren",
        meta: {
            title: "常用出游人",
            requireAuth:true
        },
        component: () => import("@/views/personal/chuYouRen")
    },
    {
        path: "/personal/addChuyouren",
        name: "addChuyouren",
        meta: {
            title: "添加常用出游人",
            requireAuth:true
        },
        component: () => import("@/views/personal/addChuYouRen")
    },
    {
        path: "/href",
        name: "href",
        meta: {
            title: "跳转中",
            requireAuth:true
        },
        component: () => import("@/views/hrefPay")
    },
];

export default pageRouter