const api = {
    getThemeTemplate: `/api/mini/group/getThemeTemplate`,  // 模板
    groupSubOrgList: `/api/mini/group/groupH5SubOrgList`, //景区列表
    getOrgInfo: `/api/mini/group/getOrgInfo`,//景区info
    groupSubOrgProductList: `/api/mini/group/groupH5SubOrgProductList`,//景区门票
    productDetail: `/api/mini/productDetail`,//门票详情
    buyNow: `/api/mini/group/order/buyNow`,//立即预定
    addValid: `/api/mini/group/cart/addValid`,//加入购物车校验
    add: `/api/mini/group/cart/add`,//加入购物车
    cleanCart: `/api/mini/group/cart/cleanCart`,//清空购物车
    confirm: `/api/mini/group/order/confirm`,//下单信息
    orderList: `/api/mini/group/order/orderList`,//订单列表
    addPlayer: `/api/mini/group/player/add`,//添加出游人
    playerList: `/api/mini/group/player/list`, //出游人列表
    playerModify: `/api/mini/group/player/modify`, //获取出游人info
    savePlayerModify: `/api/mini/group/player/saveModify`,//保存出游人编辑
    playerDel: `/api/mini/player/del`,//删除出游人
    submitOrder: `/api/mini/group/order/submitOrder`,//提交订单
    getPayInfo: `/api/mini/order/getH5PayInfo`,//H5专用获取支付请求路径
    getDayProduct: `/api/mini/group/order/getDayProduct`,//获取预约时间
    orderDetail: `/api/mini/group/order/orderDetail`,//订单详情
    qrcode: `/qrcode`,
    getAnKangStatusInfo: `/scenic/client/order/getAnKangStatusInfo`,//安康码查询
    refundTicket: `/api/mini/order/refundTicket`,  //退票
    orderCancel: `/api/mini/order/cancel`,  //取消订单  
    orderDel: `/api/mini/order/del`,//删除订单
    modifyCart: `/api/mini/cart/modify`,//修改cart
    getUserInfo: `/mini/wst/h5/getUserInfo`,//用户信息
}

const data = {
    baseURL: 'https://ds.cnjingqu.com/client/',
    orgId: 381,//localStorage.getItem('orgId') || 336,//景区id，生产环境
    // orgId: 156,//景区id，生产环境
    sessionId: localStorage.getItem('sessionId') || '01a1cbdd-a965-4b95-9d4f-62bc642776ec'
}

export {
    api,
    data
}